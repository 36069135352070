<script>
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  lineChartDailyCreatedPosStations,
  lineChartMonthlyCreatedPosStations,
  lineChartDailyCreatedPosHubs,
  lineChartMonthlyCreatedPosHubs,
  lineChartDailyCreatedPosManagers,
  lineChartMonthlyCreatedPosManagers
} from "./data-apex";

/**
 * Apex-chart component
 */
export default {
  page: {
    title: "Apex Chart",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { PageHeader },
  data() {
    return {
      lineChartDailyCreatedPosStations: lineChartDailyCreatedPosStations,
      lineChartMonthlyCreatedPosStations: lineChartMonthlyCreatedPosStations,
      lineChartDailyCreatedPosHubs: lineChartDailyCreatedPosHubs,
      lineChartMonthlyCreatedPosHubs: lineChartMonthlyCreatedPosHubs,
      lineChartDailyCreatedPosManagers: lineChartDailyCreatedPosManagers,
      lineChartMonthlyCreatedPosManagers: lineChartMonthlyCreatedPosManagers,
      pos_stations_daily_charts: [],
      pos_stations_monthly_charts: [],
      pos_hubs_daily_charts: [],
      pos_hubs_monthly_charts: [],
      pos_managers_daily_charts: [],
      pos_managers_monthly_charts: [],

      title: "Apex charts",
      items: [
        {
          text: "Charts",
          href: "/"
        },
        {
          text: "Apex charts",
          active: true
        }
      ]
    };
  },
  methods: {
    async getPosStationsRegisteredCharts() {

      const response = await this.http.get('charts/pos-stations-registered-charts')

      this.pos_stations_daily_charts = response.data.pos_stations_daily_registered
      this.pos_stations_monthly_charts = response.data.pos_stations_monthly_registered

      this.lineChartDailyCreatedPosStations.series = [],
        this.lineChartMonthlyCreatedPosStations.series = [],

        this.lineChartDailyCreatedPosStations.series.push({
          name: this.$t('charts.pos_stations'),
          data: this.pos_stations_daily_charts.map((chart) => (chart.y ? chart.y : 0))
        })
      this.lineChartMonthlyCreatedPosStations.series.push({

        name: this.$t('charts.pos_stations'),
        data: this.pos_stations_monthly_charts.map((chart) => (chart.y ? chart.y : 0))
      })






      this.lineChartDailyCreatedPosStations.chartOptions.xaxis.categories = this.pos_stations_daily_charts.map((chart) => (chart.label ? chart.label : 0))
      this.lineChartMonthlyCreatedPosStations.chartOptions.xaxis.categories = this.pos_stations_monthly_charts.map((chart) => (chart.label ? chart.label : 0))



    },

    async getHubsRegisteredCharts() {

      const response = await this.http.get('charts/pos-hubs-registered-charts')

      this.pos_hubs_daily_charts = response.data.pos_hubs_daily_registered
      this.pos_hubs_monthly_charts = response.data.pos_hubs_monthly_registered
      this.lineChartDailyCreatedPosHubs.series = [],
        this.lineChartMonthlyCreatedPosHubs.series = [],

        this.lineChartDailyCreatedPosHubs.series.push({
          name: this.$t('charts.pos_hubs'),
          data: this.pos_hubs_daily_charts.map((chart) => (chart.y ? chart.y : 0))
        })

      this.lineChartMonthlyCreatedPosHubs.series.push({

        name: this.$t('charts.pos_hubs'),
        data: this.pos_hubs_monthly_charts.map((chart) => (chart.y ? chart.y : 0))
      })






      this.lineChartDailyCreatedPosHubs.chartOptions.xaxis.categories = this.pos_hubs_daily_charts.map((chart) => (chart.label ? chart.label : 0))
      this.lineChartMonthlyCreatedPosHubs.chartOptions.xaxis.categories = this.pos_hubs_monthly_charts.map((chart) => (chart.label ? chart.label : 0))


    },
    async getPosManagersCharts() {

      const response = await this.http.get('charts/pos-managers-registered-charts')

      this.pos_managers_daily_charts = response.data.pos_managers_daily_registered
      this.pos_managers_monthly_charts = response.data.pos_managers_monthly_registered
      this.lineChartDailyCreatedPosManagers.series = [],
        this.lineChartDailyCreatedPosManagers.series = [],
        console.log('res :', response.data);
      this.lineChartDailyCreatedPosManagers.series.push({
        name: this.$t('charts.pos_managers'),
        data: this.pos_managers_daily_charts.map((chart) => (chart.y ? chart.y : 0))
      })

      this.lineChartMonthlyCreatedPosManagers.series.push({

        name: this.$t('charts.pos_managers'),
        data: this.pos_managers_monthly_charts.map((chart) => (chart.y ? chart.y : 0))
      })






      this.lineChartDailyCreatedPosManagers.chartOptions.xaxis.categories = this.pos_managers_daily_charts.map((chart) => (chart.label ? chart.label : 0))
      this.lineChartMonthlyCreatedPosManagers.chartOptions.xaxis.categories = this.pos_managers_monthly_charts.map((chart) => (chart.label ? chart.label : 0))


    }
  },
  async mounted() {
    this.getPosStationsRegisteredCharts()
    this.getHubsRegisteredCharts()
    this.getPosManagersCharts()
  },
};
</script>

<template>
  <PageHeader :title="this.$t('charts.charts')" />
  <div class="row">
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('charts.daily_registered_pos_managers') }}</h4>
            <!-- Registered POS Managers (Daily) -->
            <apexchart v-if="pos_managers_daily_charts.length != 0" class="apex-charts" height="380" type="line" dir="ltr"
              :series="lineChartDailyCreatedPosManagers.series" :options="lineChartDailyCreatedPosManagers.chartOptions">
            </apexchart>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('charts.monthly_registered_pos_managers') }}</h4>
            <!-- Registered POS Managers (Monthly) -->
            <apexchart v-if="pos_managers_monthly_charts.length != 0" class="apex-charts" height="380" type="line"
              dir="ltr" :series="lineChartMonthlyCreatedPosManagers.series"
              :options="lineChartMonthlyCreatedPosManagers.chartOptions">
            </apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ $t('charts.daily_registered_pos_stations') }}</h4>
          <!-- Registered POS Stations (Daily) -->
          <apexchart v-if="pos_stations_daily_charts.length != 0" class="apex-charts" height="380" type="line" dir="ltr"
            :series="lineChartDailyCreatedPosStations.series" :options="lineChartDailyCreatedPosStations.chartOptions">
          </apexchart>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ $t('charts.monthly_registered_pos_stations') }}</h4>
          <!--Registered POS Stations (Monthly) -->
          <apexchart v-if="pos_stations_monthly_charts.length != 0" class="apex-charts" height="380" type="line" dir="ltr"
            :series="lineChartMonthlyCreatedPosStations.series"
            :options="lineChartMonthlyCreatedPosStations.chartOptions"></apexchart>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ $t('charts.daily_registered_pos_hubs') }}</h4>
          <!-- Registered POS Hubs (Daily) -->
          <apexchart v-if="pos_hubs_daily_charts.length != 0" class="apex-charts" height="380" type="line" dir="ltr"
            :series="lineChartDailyCreatedPosHubs.series" :options="lineChartDailyCreatedPosHubs.chartOptions">
          </apexchart>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ $t('charts.monthly_registered_pos_hubs') }}</h4>
          <!-- Registered POS Hubs (Monthly) -->
          <apexchart v-if="pos_hubs_monthly_charts.length != 0" class="apex-charts" height="380" type="line" dir="ltr"
            :series="lineChartMonthlyCreatedPosHubs.series" :options="lineChartMonthlyCreatedPosHubs.chartOptions">
          </apexchart>
        </div>
      </div>
    </div>
  </div>

  <!-- end row -->

  <!-- end row -->
</template>